<script>
import Multiselect from "vue-multiselect";

import {
  required,
  email
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

/**
 * Form Validation component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      form: {
        name: null,
        gender: null,
        email: null,
        phone: null,
        status: true
      },
      isGenderNotSelected: false,
      optionsGender: [
        "male",
        "female",
      ],
      options: [
        { name: 'Active', value: 1 },
        { name: 'Not Active', value: 0 }
      ],
      isStatusNotSelected: false,
      typesubmit: false,
      isError: false,
      errorMessage: null
    };
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },  
    }
  },
  mounted() {
    this.fetchEmployee()
  },
 
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {

        this.isGenderNotSelected = (this.form.gender === null)? true : false
        this.isStatusNotSelected = (this.form.status === null)? true : false

        if(this.isGenderNotSelected || this.isStatusNotSelected) {
          return false
        }
        // do your submit logic here
        let param = new FormData();
        param.append('name', this.form.name)
        param.append('gender', this.form.gender)
        param.append('email', this.form.email) 
        param.append('phone', this.form.phone)
        param.append('status', this.form.status.value)

        this.$axios.put(`/employee/${this.$route.params.id}`, param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Employee Updated Successfully", "success");
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    // get detail user
    fetchEmployee() {
      this.$axios.get(`/employee/${this.$route.params.id}`)
        .then( (response) => { 
          const result = response.data.result
          console.log(result)
          
          if(result.employee.treatment) {
            result.employee.treatment.treatmentId = result.employee.treatment._id
          }

          let status;
          this.options.forEach( data => {
            if(data.value === result.employee.status){
              status = data
            }
          })

          this.form = {
            name: result.employee.name,
            gender: result.employee.gender,
            email: result.employee.email,
            phone: result.employee.phone,
            status: status,
            treatment: result.employee.treatment
          }

        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-alert
            v-model="isError"
            variant="danger"
            class="mt-3"
            dismissible
          >{{errorMessage}}</b-alert>
          <form action="#" @submit.prevent="onSubmit">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="form.name"
                type="text"
                class="form-control"
                name="name"
                :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
              />
              <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
                <span v-if="!$v.form.name.required">This value is required.</span>
              </div>
            </div> 

            <div class="form-group">
              <label>Gender</label> 
              
              <multiselect v-model="form.gender" 
                :class="{ 'is-invalid': isGenderNotSelected }"
                :options="optionsGender"
                deselect-label="Can't remove this value" 
                placeholder="Select one" :searchable="false" :allow-empty="false">
              </multiselect>
              
              <div v-if="isGenderNotSelected" class="invalid-feedback">
                <span>This value is required.</span>
              </div>
            </div>

            <div class="form-group">
              <label>Email</label>
              <div>
                <input
                  v-model="form.email"
                  type="email"
                  name="email"
                  class="form-control"
                  :class="{ 'is-invalid': typesubmit && $v.form.email.$error }"
                />
                <div v-if="typesubmit && $v.form.email.$error" class="invalid-feedback">
                  <span v-if="!$v.form.email.required">This value is required.</span>
                  <span v-if="!$v.form.email.email">This value should be a valid email.</span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Phone</label>
              <input
                v-model="form.phone"
                type="number"
                class="form-control"
                name="phone"
                :class="{ 'is-invalid': typesubmit && $v.form.phone.$error }"
              />
              <div v-if="typesubmit && $v.form.phone.$error" class="invalid-feedback">
                <span v-if="!$v.form.phone.required">This value is required.</span>
              </div>
            </div>

            <div class="form-group">
              <label>Status</label>
              <multiselect :class="{ 'is-invalid': isStatusNotSelected }" v-model="form.status" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select one" :options="options" :searchable="false" :allow-empty="false">
                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
              </multiselect>
              <div v-if="isStatusNotSelected" class="invalid-feedback">
                <span>This value is required.</span>
              </div>
            </div>

            <div class="form-group mb-0">
              <div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <!-- end card-body -->
      </div>
    </div>
    <!-- end col-->
  </div>
</template>